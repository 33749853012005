<template>
  <div class="servOverviewComp">
    <template v-if="services.length">
      <Service
        v-for="service in services"
        :key="service.name"
        :settings="service"
      />
    </template>
    <template v-else>
      <div class="noServices">
        There are no services.
      </div>
    </template>
  </div>
</template>

<script>
import Service from "./Service.vue";
export default {
  components: { Service },
  name: "ServOverviewComp",
  created: async function () {
    const response = await fetch("config/services.json");
    this.services = await response.json();
  },
  data: function () {
    return {
      services:{}
    };
  }
};
</script>

<style scoped>
.servOverviewComp {
  margin: 10px auto 0 auto;
  width: 80%;
  min-width: min-content;
  max-width: max-content;
  background-color: rgba(0, 51, 255, 0.11);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2px;
  border-radius: 4px;
}

.servOverviewComp::after {
  display: flex;
  content: "";
  flex: auto;
}

.noServices {
  color: #c8c8c8;
  margin: 4px;
}
</style>