<template>
  <div class="titleComp">{{ text }}</div>
</template>

<script>
export default {
  name: "TitleComp",
  props: {
    text: String,
  },
};
</script>

<style scoped>
.titleComp {
  margin: 10vw auto 0 auto;
  width: max-content;
  font-size: 2em;
  color: #e6e6e6;
}
</style>