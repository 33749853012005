<template>
  <div id="app">
    <TitleComp :text="'Übersicht'" />
    <ServOverviewComp />
  </div>
</template>

<script>
import ServOverviewComp from "./components/ServOverviewComp.vue";
import TitleComp from "./components/TitleComp.vue";

export default {
  components: { ServOverviewComp, TitleComp },
  name: "App",
  data: function () {
    return {
      host: location.hostname,
    };
  },
};
</script>

<style>
#app {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: #282931;
}
</style>
