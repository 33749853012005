<template>
  <a :href="settings.url" target="_blank" class="service">{{
    settings.name
  }}</a>
</template>

<script>
export default {
  name: "Service",
  props: {
    settings: {},
  },
};
</script>

<style scoped>
/* unvisited link */
a:link {
  color: #fff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #fff;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #fff;
  text-decoration: none;
}

/* selected link */
a:active {
  color: #fff;
  text-decoration: none;
}
</style>
<style>
.service:hover {
  border-color: #0000005d;
  background-color: rgba(0, 51, 255, 0.28);
}

.service {
  color: #fff;
  text-decoration: none;
  margin: 4px;
  border-color: #0000003d;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.12);
}
</style>